<template>
<div class="certcode">
  <div class="top">
    <MyBreadcrumb></MyBreadcrumb>
  </div>
  <div class="certcode-cont">
    <div class="btn-top">
      <div class="btns-group">
        <Button type="primary" class="mr10 btn-item" @click="uploadFileBtn" v-if="isBoss">导入激活码</Button>
        <Button type="primary" class="mr10 btn-item" @click="remarks" >备注分销商</Button>
        <Button type="primary" class="mr10 btn-item" @click="dispenseCode" v-if="isBoss">分配激活码</Button>
        <Button type="primary" class="mr10 btn-item" @click="toDispenseList()">分发管理</Button>
      </div>
      <div class="right">
        <el-select size="small" v-model="status" clearable class="search-item" @change="getList()" placeholder="选择状态筛选">
          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-date-picker
          class="search-item"
          v-model="endtime_from"
          type="date"
          size="small"
          value-format="timestamp"
          @change="getList()"
          :picker-options="pickerOptions"
          placeholder="选择到期开始时间筛选">
        </el-date-picker>
        <el-date-picker
          class="search-item"
          v-model="endtime_to"
          type="date"
          size="small"
          value-format="timestamp"
          @change="getList()"
          :picker-options="pickerOptions"
          placeholder="选择到期结束时间筛选">
        </el-date-picker>
        <Input v-model="keyword"
          :placeholder="$t('common_search_keyword')"
          clearable
          search
          :enter-button="true"
          style="margin-bottom:12px;"
          @on-search="changeKeyword"
          class="search-item"
        />
      </div>

    </div>
    <div class="table">
      <Table border :columns="columns" :data="dataList" :loading="loading"  @on-sort-change="sortChange"></Table>
    </div>
    <div class="page">
      <div>
        <Button type="primary" class="mr10" @click="leadingOut(ids)" v-if="isBoss">导出本页数据</Button>
        <Button type="primary" class="mr10" @click="leadingOut(all_ids)" v-if="isBoss">导出全部数据</Button>
      </div>
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>
  <Modal
      v-model="remarksModal"
      title="备注分销商"
  >
    <div>
      <span>选择分销商：</span>
<!--      <Select v-model="siteId"-->
<!--              ref="select"-->
<!--              remote-->
<!--              :remote-method="remoteSite"-->
<!--              filterable class="w300" placeholder="请选择分销商"-->
<!--      >-->
<!--        <Option v-for="item in siteList" :value="item.id" :key="item.id">{{ item.name }}(id:{{item.id}})</Option>-->
<!--      </Select>-->
      <el-select
          v-model="remarksDistributor"
          filterable
          allow-create
          default-first-option
          clearable
          class="w300"
          placeholder="请选择分销商"
      >
        <el-option
            v-for="item in distributors"
            :key="item.name"
            :label="item.name"
            :value="item.name">
        </el-option>
      </el-select>
    </div>
    <Input v-model="codes"  type="textarea" :rows="5" placeholder="请输入激活码" style="margin-top: 20px;" />
    <div slot="footer">
      <Button @click="cancelRemarks">取消</Button>
      <Button type="primary" @click="confirmRemarks">确定</Button>
    </div>
  </Modal>
  <Modal
      v-model="dispenseModal"
      title="分配激活码"
      width="1000px"
  >
<!--    <div class="search">-->
<!--      <div class="search-item">-->
<!--        <div class="name">-->
<!--          一级分类:-->
<!--        </div>-->
<!--        <div class="cont">-->
<!--          <div class="values">-->
<!--            <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="search-item">-->
<!--        <div class="name">-->
<!--          二级分类:-->
<!--        </div>-->
<!--        <div class="cont">-->
<!--          <div class="values" >-->
<!--            <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="search-item">-->
<!--        <div class="name">-->
<!--          三级分类:-->
<!--        </div>-->
<!--        <div class="cont">-->
<!--          <div class="values" >-->
<!--            <div :class="curThirdCategoryId == item.id ? 'active' : ''" v-for="item in thirdCategoryList" :key="item.id + 'ThirdCategory'" @click="changeSearch(item,'curThirdCategoryId')">{{item.name}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="dispense-item">
      <span class="name">选择分销商：</span>
<!--      <Select v-model="codeCountSiteId"-->
<!--              ref="select"-->
<!--              remote-->
<!--              :remote-method="remoteSite"-->
<!--              filterable placeholder="请选择分销商"-->
<!--              class="value"-->
<!--      >-->
<!--        <Option v-for="item in siteList" :value="item.id" :key="item.id">{{ item.name }}(id:{{item.id}})</Option>-->
<!--      </Select>-->
      <el-select
          v-model="distributor"
          filterable
          allow-create
          default-first-option
          clearable
          class="value"
          placeholder="请选择分销商"
      >
        <el-option
            v-for="item in distributors"
            :key="item.name"
            :label="item.name"
            :value="item.name">
        </el-option>
      </el-select>
    </div>
    <div class="dispense-item">
      <span class="name">选择分类：</span>
      <Select v-model="categoryName"
              ref="select"
              remote
              filterable placeholder="请选择分类"
              clearable
              class="value"
      >
        <Option v-for="item in categoryNames" :value="item.name" :key="item.id">{{ item.name }}({{item.has_count}})</Option>
      </Select>
    </div>
    <div class="dispense-item">
      <span class="name">数量：</span>
      <Input v-model="codeCountNum" type="number" placeholder="请输入分配激活码数量" class="value" />

    </div>
    <div class="dispense-item">
      <span class="name">备注：</span>
      <Input v-model="dispenseRemarks" type="textarea" :row="2" placeholder="请输入备注" class="value" />

    </div>
    <div class="dispense-item">
      <span class="name">是否显示时间：</span>
      <i-switch v-model="showTime" />

    </div>
    <div slot="footer">
      <Button @click="cancelDispenseCode">取消</Button>
      <Button type="primary" @click="confirmDispenseCode">确定</Button>
    </div>
  </Modal>
  <Modal
      v-model="uploadModal"
      title="导入激活码"
      width="1000px"
  >
<!--    <div class="search">-->
<!--      <div class="search-item">-->
<!--        <div class="name">-->
<!--          一级分类:-->
<!--        </div>-->
<!--        <div class="cont">-->
<!--          <div class="values">-->
<!--            <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="search-item">-->
<!--        <div class="name">-->
<!--          二级分类:-->
<!--        </div>-->
<!--        <div class="cont">-->
<!--          <div class="values" >-->
<!--            <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="search-item">-->
<!--        <div class="name">-->
<!--          三级分类:-->
<!--        </div>-->
<!--        <div class="cont">-->
<!--          <div class="values" >-->
<!--            <div :class="curThirdCategoryId == item.id ? 'active' : ''" v-for="item in thirdCategoryList" :key="item.id + 'ThirdCategory'" @click="changeSearch(item,'curThirdCategoryId')">{{item.name}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <p>导入文件格式(".xlsx",".xls")</p>
    <div class="dispense-table">
      <Table :columns="codeColumns" :data="codeDataList"></Table>
    </div>
    <Upload :action="constant.URL + '/uploadfile/upload'"
            :data="{
                      type:0
                    }"
            accept=".xlsx,.xls"
            :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
            :on-success="fileSuccess"
            :show-upload-list="false"
    >
      <Button icon="ios-cloud-upload-outline">选择文件</Button>
      <span style="padding-left: 20px">{{ uploadFile ? uploadFile : '未选择文件' }}</span>
    </Upload>
    <div slot="footer">
      <Button @click="cancelUpload">取消</Button>
      <Button type="primary" @click="confirmUpload">确定</Button>
    </div>
  </Modal>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import Cookies from "js-cookie";
export default {
  name: "list",
  data(){
    return {
      Cookies:Cookies,
      columns: [
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: '激活码',
          key: 'code'
        },
        {
          title: '名称',
          key: 'name',
        },
        {
          title: '分销商',
          key: 'distributor',
        },
        // {
        //   title: '分类',
        //   key: 'name',
        // },
        {
          title: '状态',
          key: 'status',
          sortable: 'custom'
        },
        {
          title: '创建时间',
          key: 'rate',
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
                )
            )
          }
        },
        {
          title: '到期时间',
          sortable: 'custom',
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.endtime * 1000), 'yyyy-MM-dd hh:mm'
                )
            )
          }
        },
        {
          title: '修改时间',
          key: 'updated_at',
          sortable: 'custom',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.updated_at * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '分发id',
          key: 'dispense_id',
        },
      ],
      dataList: [],
      total:0,
      page:1,
      pageSize:10,
      keyword:'',
      endtime_from:"",
      endtime_to:"",
      loading:false,
      codeCount:'',
      // codeCountList:[],
      remarksModal:false,
      siteId:'',
      siteList:[],
      codes:'',
      dispenseModal:false,
      showTime:false,
      codeColumns:[
        {
          title: '授权码',
          key: 'code'
        },
        {
          title: '所属商品',
          key: 'name'
        },
        {
          title: '状态',
          key: 'status'
        },
        {
          title: '创建时间',
          key: 'createData',
          width:140
        },
        {
          title: '到期时间',
          key: 'endData',
          width:140
        },
      ],
      pickerOptions:{
        disabledDate(time){
          const year=new Date(time).getFullYear();
          const start=new Date().getFullYear()-4;
          const end=new Date().getFullYear()+3;
          if(year<start || year>end){
            return year;
          }
        }
      },
      codeDataList:[
        {
          code:'MPYGWUJOBKOHLAZ5J41Ntd',
          name:'阿里云助理工程师认证ACA',
          status:'INACTIVATED',
          createData:'2021/1/13',
          endData:'2022/1/14',
        },
        {
          code:'MPYGWUJOBKOHLAZ5J41Ntd',
          name:'阿里云助理工程师认证ACA',
          status:'ACTIVATED',
          createData:'2021/1/13',
          endData:'2022/1/14',
        }
      ],
      uploadFile:'',
      uploadModal:false,
      codeCountNum:'',
      codeCountSiteId:'',
      curCategoryId:'',
      cursecondCategoryId:'',
      curThirdCategoryId:'',
      categoryList:[],
      secondCategoryList:[],
      thirdCategoryList:[],
      ids:'',
      all_ids:"",
      categoryNames:[],
      categoryName:'',
      dispenseRemarks:'',
      isBoss:false,
      distributors:[],
      distributor:'',
      remarksDistributor:'',
      sort:'',
      orderby:'',
      status:"",
      statusList:[]
    }
  },
  watch:{
    '$store.state.user.userInfo.id':{
      handler(newV){
        if(newV){
          this.setBtnPermissions();
        }
      },
      immediate:true
    }
  },
  created(){

    this.getList();
    // this.getSiteList();
    this.getCategoryList();
  },
  methods:{
    setBtnPermissions(){  //设置按钮权限  boss 全部可见  其他 只能分配
      //console.log(this.$store.state.user.userInfo.roles,'dddddd')
      for(let name in this.$store.state.user.userInfo.auth_roles){
        if(name === 'role_boss'){
          this.isBoss = true;
        }
      }
    },
    sortChange(e){
      //console.log(e,'orderorderorder')
      if(e.order == 'asc' || e.order == 'desc'){
        this.sort = e.order;
        this.orderby = e.key;
      }else{
        this.sort = '';
        this.orderby = '';
      }
      this.getList();
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        sort:this.sort,
        orderby:this.orderby,
        endtime_from:this.endtime_from?this.endtime_from/1000:'',
        endtime_to:this.endtime_to?this.endtime_to/1000:'',
        status:this.status
      };
      this.loading = true;
      this.api.certcode.datasetCertCodeList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.total = Number(res.count);
        this.categoryNames = res.categoryNameList;
        // for(let name in res.categoryNames){
        //   this.categoryNames.push({
        //     name:res.categoryNames[name],
        //     id:name
        //   })
        // }
        this.distributors = [];
        for(let name in res.distributors){
            this.distributors.push({
              name:res.distributors[name],
            })
        }
        this.ids = res.id;
        this.all_ids=res.all_ids;

        const arr=[];
        Object.keys(res.statuses).forEach(key=>{
          arr.push({
            id:key,
            name:res.statuses[key]
          });
        });
        this.statusList=arr;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    leadingOut(ids){
      let params = {
        ids:ids,
        is_show:this.showTime,
      };
      this.api.certcode.download(params).then((res)=>{
        util.downLoad(res)
      })
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    // getSiteList(data){
    //   let params = {
    //     pageSize:20,
    //     page:1,
    //     keyword:data || '',
    //   };
    //   this.api.site.siteList(params).then((res)=>{
    //     this.siteList = res.list;
    //   })
    // },
    // remoteSite (query) {
    //   //console.log(query,'query')
    //   if (query !== '' && query != this.siteId) {
    //     this.getSiteList(query);
    //   } else {
    //     this.siteList = [];
    //   }
    // },
    fileSuccess(response,file, fileList){  //上传文件成功
      // console.log(response.data.info.upload_path,'dddd')
      this.uploadFile = response.data.info.name + '.' + response.data.info.file_extension;
      this.uploadFileId = response.data.info.id;
    },
    confirmUpload(){
      if(!this.uploadFileId){
        this.$Message.warning('请选择文件～');
        return;
      }
      let params = {
        uploadfile_id:this.uploadFileId,
        // category_id:this.curThirdCategoryId
      }
      this.api.certcode.importUploadfile(params).then((res)=>{
        this.$Message.success('上传成功～');
        this.getList();
        this.uploadModal = false;
      })
    },
    uploadFileBtn(){
      this.uploadModal = true;
      this.uploadFile = '';
      this.uploadFileId = '';
      this.curCategoryId = this.categoryList[0].id;
      this.cursecondCategoryId = this.secondCategoryList[0].id;
      this.curThirdCategoryId = this.thirdCategoryList[0].id
    },
    cancelUpload(){
      this.uploadModal = false;
    },
    remarks(){
      this.remarksModal = true;
      this.remarksDistributor = '';
      this.codes = '';
    },
    cancelRemarks(){
      this.remarksModal = false;
    },
    confirmRemarks(){
      if(!this.remarksDistributor){
        this.$Message.warning('请选择经销商～');
        return;
      }
      if(!this.codes){
        this.$Message.warning('请输入激活码～');
        return;
      }
      let params = {
        distributor:this.remarksDistributor,
        keys:this.codes
      };
      this.api.certcode.setDistributors(params).then((res)=>{
        this.$Message.success('操作成功～');
        this.getList();
        this.remarksModal = false;
      })
      //console.log(params,'paramsparamsparamsparams')
    },
    dispenseCode(){
      this.dispenseModal = true;
      this.distributor = '';
      this.codeCountNum = '';
      this.categoryName = '';
      this.showTime = false;
      this.dispenseRemarks = '';
    },
    cancelDispenseCode(){
      this.dispenseModal = false;
    },
    confirmDispenseCode(){
      if(!this.distributor){
        this.$Message.warning('请选择经销商～');
        return;
      }
      if(!this.categoryName){
        this.$Message.warning('请选择分类～');
        return;
      }
      if(!this.codeCountNum){
        this.$Message.warning('请输入数量～');
        return;
      }

      let params = {
        distributor:this.distributor,
        number:this.codeCountNum,
        category_name:this.categoryName,
        is_show:this.showTime,
        remarks:this.dispenseRemarks
      };
      this.api.certcode.distribution(params).then((res)=>{
        this.$Message.success('操作成功～');
        this.leadingOut(res.ids);
        this.dispenseModal = false;
        this.getList();
      })
    },
    changeSearch(data,name) {

      this[name] = data.id;
      if (name == 'curCategoryId') {
        this.cursecondCategoryId = '';
        this.curThirdCategoryId = ''
        this.thirdCategoryNum = 0;
        this.getSecondCategoryList();
      } else if (name == 'cursecondCategoryId') {
        this.curThirdCategoryId = ''
        this.thirdCategoryNum = 0;
        this.getThirdCategoryList();
      } else if (name == 'curThirdCategoryId') {
        this.thirdCategoryNum = data.data_count;
      }
    },
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        site_id:-1,
        user_id:-1,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //一级标签
        this.categoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;

        this.getSecondCategoryList();

      })
    },
    getSecondCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        site_id:-1,
        user_id:-1,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //二级标签
        this.secondCategoryList = [
          ...res.categorySecondList
        ];
        if(this.secondCategoryList.length){
          this.cursecondCategoryId = this.secondCategoryList[0].id;
          this.getThirdCategoryList();
        }


      })
    },
    getThirdCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        site_id:-1,
        user_id:-1,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        this.thirdCategoryList = [
          ...res.categoryThirdList
        ]
        if(this.thirdCategoryList.length){
          this.curThirdCategoryId = this.thirdCategoryList[0].id
        }


      })
    },
    toDispenseList(){
      this.$router.push({
        path:'/certcode/dispense-list',
        query:{
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.certcode{
  .top{
    margin: 20px 0 0 20px;
  }
  .certcode-cont{
    margin: 20px;
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    .btn-top{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btns-group{
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        .btn-item{
          margin-bottom: 10px;
        }
      }
    }
    .right{
      display: flex;
      flex-shrink: 1;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-items: center;
      .search-item{
        margin-bottom: 10px;
        margin-left: 10px;
        width:200px;
      }
    }
    .page{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
  }
  .mr10{
    margin-right: 10px;
  }
}
.dispense-item{
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .name{
    width: 100px;
    text-align: right;
  }
  .value{
    flex: 1;
    width: 0;
  }
}
.dispense-table{
  margin: 20px 0;
}
.search{
  margin-top: 20px;
  margin-bottom: 20px;
  .search-item{
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;

    .name{
      padding: 5px 0;
      width: 75px;
      text-align: right;
    }
    .cont{
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values{
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div{
          padding: 5px 10px;
          //margin: 0 10px 10px 5px;
          cursor: pointer;
        }
        >div.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
